import React, {useState} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SliderIndex from "../components/slider-index"
import ReactModal from 'react-modal'
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

const hrstyle = {width: 'full', borderTopWidth: '1px!important', marginLeft: 'auto', marginRight: 'auto'};
const modalStyles = {
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.58)",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
    },
    content: {
        position: "relative",
        top: "auto",
        left: "auto",
        right: "auto",
        bottom: "auto",
        maxWidth: "600px",
        margin: "120px auto 0",
        padding: 0,
        border: 0,
    },
};

const axios = require("axios");
const jQuery = require("jquery");

const ConcursoPage = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const modalCloseTimeout = 300;
    const closeModal = () => {
        setModalOpen(false);
    };
    const openModal = () => {
        setModalOpen(true);
    };
    const solicitar = () => {
        setModalOpen(false);
    };

    const {executeRecaptcha} = useGoogleReCaptcha()
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [subject] = useState("Sitio Fundación Pedro Pérez Garrido - Formulario de Donativos");
    const [message, setMessage] = useState("")
    const [phone, setPhone] = useState("")
    const [sitio] = useState("Fundación Pedro Pérez Garrido");

    const send = async function (e) {
        e.preventDefault()

        jQuery("#btn-cont").css('pointer-events', 'none')
        jQuery("#btn-cont").html('Enviando...')

        if (!executeRecaptcha) {
            return
        }

        if (email.length === 0 || email.trim().length === 0) {
            return
        } else if (message.length === 0 || message.trim().length === 0) {
            return
        } else if (subject.length === 0 || subject.trim().length === 0) {
            return
        } else if (name.length === 0 || name.trim().length === 0) {
            return
        }
        const result = await executeRecaptcha("contacto")
        const headers = {
            "Content-Type": "application/json"
        }
        axios
            .post(
                "https://us-central1-cloudsites-190318.cloudfunctions.net/mails",
                {
                    name,
                    email,
                    message,
                    subject,
                    phone,
                    sitio,
                    token: result
                },
                {
                    headers: headers
                }
            )
            .then(res => {
                jQuery("#donativo-form").slideUp("slow").hide();
                jQuery("#result").append(
                    '<hr class="mt-4"><div class="success text-center"><h4>¡Correo electrónico enviado con éxito!</h4><br><p>Gracias por usar nuestro formulario de contacto</p></div><hr>'
                )
            })
    }

    return (
        <Layout>
            <SEO title="Home"/>
            {/* ============================================================== */}
            {/* Feature 3  */}
            {/* ============================================================== */}
            <div className=" bg-white spacer feature3 mt-5">
                <div className="container">
                    {/* Row  */}
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="title">Concurso de fotografía Chetumal</h3>
                            <p className="subtitle">Chetumal; el lugar donde crecen los árboles rojos. Una de las ciudades más ricas en naturaleza,
en donde ver el atardecer se vuelve casi una pieza poética. Muchas veces, solemos dar por
sentado lo bella e increíble que es nuestra ciudad, como chetumaleños, debemos estar orgullosos
de lo que es nuestro, y saber que somos dueños de nuestra tierra, luchar por un mejor Chetumal,
es trabajo de todos y cada uno de sus habitantes.
                            </p>
                            <p className="mt-20">En Fundación Pedro Pérez Garrido, quisimos que se transmitiera a través de arte, lo que significa
ser de Chetumal, les queremos agradecer a todas las personas que fueron participes del
concurso, sin duda, nuestra ciudad es única, y es un privilegio poder ser parte de ella. Nos
complace poder compartirles las grandes piezas fotográficas de los ganadores.
                            </p>
                           
                        </div>
                    </div>
                    {/* Row  */}


                    <div className="row mt-5 justify-content-center">
                       

                        <div className="col-12">
                            <div className="image-control text-center marco">
                                <picture>
                                    
                                    <img alt="" title=""
                                         src={`../../images/concurso/Foto 1.JPG`}
                                         className="img-responsive  "
                                         style={{marginBottom: '10px', borderRadius: '0px'}}/>
                                </picture>
                            </div>

                     
                        </div>
                        <div className="bg-red  p-4 col-10 mt-4 ">
                        <h2 className="text-white text-center">Primer Lugar: Brenda Lizet Chuc Pech - Tranquilidad</h2>
                        </div>
                    </div>
                    
                    <div className="row mt-5 justify-content-center">
                       

                       <div className="col-12">
                           <div className="image-control text-center marco">
                               <picture>
                                   
                                   <img alt="" title=""
                                        src={`../../images/concurso/Foto 2.JPG`}
                                        className="img-responsive  "
                                        style={{marginBottom: '10px', borderRadius: '0px'}}/>
                               </picture>
                           </div>

                    
                       </div>
                       <div className="bg-red  p-4 col-10 mt-4 ">
                       <h2 className="text-white text-center">Segundo Lugar: Jesús Ernesto Rodríguez Uc - Chetumal, donde se pone el sol</h2>
                       </div>
                   </div>
                   
                   <div className="row mt-5 justify-content-center">
                       

                       <div className="col-12">
                           <div className="image-control text-center marco">
                               <picture>
                                   
                                   <img alt="" title=""
                                        src={`../../images/concurso/Foto 3.JPG`}
                                        className="img-responsive  "
                                        style={{marginBottom: '10px', borderRadius: '0px'}}/>
                               </picture>
                           </div>

                    
                       </div>
                       <div className="bg-red  p-4 col-10 mt-4 ">
                       <h2 className="text-white text-center">Tercer Lugar: Francisco Javier Ortiz Balam - Atardecer de oro en Chetumal</h2>
                       </div>
                   </div>
                   
                   
                </div>
            </div>
            <ReactModal
                isOpen={modalOpen}
                onRequestClose={closeModal}
                // contentLabel="Example Modal In Gatsby"
                style={modalStyles}
            >
                <div className="close p-2" onClick={closeModal}><i className="sl-icon-close"></i></div>
                <div className="p-5">
                    <p>Tras enviar este formulario, uno de los colaboradores de la Fundación se pondrá en contacto con
                        usted
                        tan pronto sea posible dentro de horarios laborales estándar en México.</p>
                    <div id="result"></div>
                    <form onSubmit={event => send(event)} method="post" id="donativo-form">
                        <div className="row">
                            <div className="col-md-6">
                                <label>Nombre completo:</label><br/>
                                <input name="name" required className="form-control mb-2"
                                       onChange={event => setName(event.target.value)}/>
                            </div>
                            <div className="col-md-6">
                                <label>Correo electrónico:</label><br/>
                                <input type="email" className="form-control mb-2" name="email"
                                       onChange={event => setEmail(event.target.value)}/>
                            </div>
                            <div className="col-md-6">
                                <label>Teléfono:</label><br/>
                                <input type="tel" className="form-control mb-2" name="phone"
                                       onChange={event => setPhone(event.target.value)}/>
                            </div>
                            <div className="col-md-6">
                                <label>Mensaje:</label><br/>
                                <textarea className="form-control" rows={1} name="mensaje" defaultValue={""}
                                          onChange={event => setMessage(event.target.value)}/>
                            </div>
                            <br/>
                            <button className="mt-3 btn btn-light mr-2" onClick={closeModal}>Cancelar</button>
                            <button id="btn-cont" className="mt-3 btn btn-info" type="submit">Enviar</button>
                        </div>
                    </form>
                </div>
            </ReactModal>
        </Layout>
    )
}

export default ConcursoPage
